#realisations {
  background-color: #2a292a;
  color: white;

  h2 {
    text-transform: uppercase;
  }

  ul {
    list-style-type: square;
    list-style-position: inside;
    margin-left: 0;
    padding-left: 0;
    li {
      a {
        color: white;
        text-transform: uppercase;
        &:hover {
          color: $green;
          text-decoration: none;
        }
      }
    }
  }
}

#certifications,
#partners{
  background-color: #f0f2f0;
  border-bottom: 80px solid $green;
  padding-bottom: 2em;
  box-shadow: inset 0 5px 10px -2px rgba(0, 0, 0, 0.4);
}

#partners,
#certifications {
  text-align: center;
  img {
    max-height: 100px;
    width: auto;
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
#footer {
  padding: 30px 0;
  background-color: $grey-darker;
  color: white;
  overflow-y: hidden;

  .logo{
    max-height: 160px;
  }

  .col-links {
    a {
      padding-right: 10px;
      img {
        height: 50px;
        width: auto;
      }
    }
  }

  #headline {
    margin-top: 4em;
  }

  #categories {
    margin-top: 40px;
    ul {
      li {
        a {
          color: white;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .el{
    font-size: 16px;
    padding-top: 30px;
  }

  .phone-lg{
    font-size: 27px;
    line-height: 27px;
  }

  .arrow-call-us{
    .call-us{
      padding-left:0;
    }
    .glyphicon{
      color: $black-text;
    }
  }
}

#footer-realisations{
  color: white;
  padding: 20px 0;

  .category{
    font-weight: 600;
    color: white;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: white;

    a{
      color: white;
    }
  }

  .el{
    display: inline-block;
    float: left;
    padding: 5px 10px;
    margin-top: 15px;
    font-size: 12px;
  }
}
