header#header {
  position: relative;
  background-color: $grey;
  color: white;
  padding: 10px 0;

  .call-us{
    position: relative;
    top: 6px;
    span{
      display: table;
    }
  }

  .logo-small{
    position: relative;
    top: 28px;
  }

  #menu {
    float: right;

    a{
      color: white;
    }

   ul{
     margin: 0;
     padding: 0;
     li{
       float: left;
       padding: 5px 6px;
       list-style: none;
       text-transform: uppercase;

       .active{
        background-color: $grey-darker;
       }
     }
   }
  }
}


#menu-realisations{
  position: absolute;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  padding: 20px 0;
  width: 100%;

  display: none;
  &.active{
    display: block;
  }

  > .container{
    margin: auto;
  }

  .category{
    font-weight: 500;
    color: black;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: black;

    a{
      color: black;
    }
  }

  .el{
    display: inline-block;
    float: left;
    padding: 5px 10px;
  }
}