@import "libs/animate";
@import "variables";
@import "layout/header";
@import "layout/main";
@import "layout/products";
@import "layout/posts";
@import "layout/footer";

@import "pages/home";
@import "pages/team";
@import "pages/products";
@import "pages/contact";

html body{
  font-family: 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.uppercase{
  text-transform: uppercase;
}

.site-warning-stripe{
  display: none;
}


.call-us{
  position: relative;
  font-weight: 600;
  background: none;

  hr{
    margin: 0.5px;
  }

  &.txt-green{
    color: $green2 !important;
  }

}

.phone-lg{
  font-size: 18px;
}

.txt-grey{
  color: $grey;
}
.txt-grey-darker{
  color: $grey-darker;
}
.txt-green{
  color: $green2;
}

.img-contain{
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
}

.carousel-control{
  background-image: none !important;
  .glyphicon{
    background-color: $grey;
    color: black;
    border-radius: 50%;
    width: 40px;
    height: 40px;

    &:before{
      position: relative;
      top: 4px;
    }
  }
}

.arrow-call-us{
  position: relative;
  font-size: 11px;
  .call-us{
    display: inline-block;
    padding-right: 5px;
    width: auto !important;
  }
  .glyphicon{
    position: relative;
    top: -8px;
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 40px;
  }

  &.txt-green{
    color: $green2;
  }
}

.not-here{
  display: none;
}

.link-call-us{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 9;
}

.vertical .carousel-inner {
  height: 100%;
}

.carousel.vertical .item {
  -webkit-transition: 0.6s ease-in-out top;
  -moz-transition: 0.6s ease-in-out top;
  -ms-transition: 0.6s ease-in-out top;
  -o-transition: 0.6s ease-in-out top;
  transition: 0.6s ease-in-out top;
}

.carousel.vertical .active {
  top: 0;
}

.carousel.vertical .next {
  top: 400px;
}

.carousel.vertical .prev {
  top: -400px;
}

.carousel.vertical .next.left,
.carousel.vertical .prev.right {
  top: 0;
}

.carousel.vertical .active.left {
  top: -400px;
}

.carousel.vertical .active.right {
  top: 400px;
}

.carousel.vertical .item {
  left: 0;
}

.carousel.vertical .carousel-control {
  width: 100%;
  bottom: inherit;
  top: inherit;
}

.carousel.vertical .carousel-control.left {
  top: 0;
}
