.products {
  padding: 40px 0 0 0;
  background-color: $grey;

  .carousel {
    background-color: $grey;
    height: 100%;
    min-height: 60vh;

    .carousel-control.right,
    .carousel-control.left {
      background-image: none;
      background-repeat: no-repeat;
    }

    .carousel-inner {
      min-height: 60vh;
      padding-bottom: 190px;
      .item {
        min-height: 60vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        .carousel-caption {
          left: 0;
          right: 0;
          bottom: -190px;
          background-color: $grey;
          padding-top: 15px;
          padding-bottom: 35px;

          h2 {
            color: $green;
            text-transform: uppercase;
          }
        }
      }
    }

    .carousel-inner > .item > img {
      margin: 0 auto;
    }

    .carousel-indicators {
      bottom: 0;
      .active {
        background-color: $green;
      }
    }
  }
}
