#top-hp{
  background-color: $bg-light;
  padding: 30px 0;

  .logo{
    max-width: 80%;
  }

  #carousel-products{
    margin-bottom: 20px;
  }

  .carousel-control .glyphicon{
    width: 15px;
    height: 15px;
    &:before{
      font-size: 10px;
      top: -16px;
    }
  }
  .carousel-indicators{
    bottom: 0 !important;

    li{
      background-color: black;
      &.active{
        background-color: $green;
      }
    }
  }
}

#hp-promotions{
  box-shadow: inset 0 5px 10px -2px rgba(0, 0, 0, 0.4);
  background-color: $bg-light;

  .el{
    .title{
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
      color: $black-text;
    }
    .ss-title{
      font-weight: 600;
      text-align: left;
      text-transform: uppercase;
      color: $black-text;
    }
    .content{

    }
  }

  #carousel-promo{
    height: 300px;
    @media screen and (max-width: 1200px) {
      height: 257px;
    }
    @media screen and (max-width: 992px) {
      height: 200px;
    }

    .carousel-inner{
      height: 100%;
    }
    .carousel-control{
      top: 50%;
    }
  }
}

#hp-who-are-we{
  background-color: $grey-darker;
  color: white;
  padding: 30px 0;

  .logo-big{
    max-height: 90px;
    display: inline-block;
  }
  .logo-small{
    max-height: 90px;
    float: right;
  }
  h2{
    font-weight: 700;
    font-size: 26px;
  }

  .content{
    font-size: 13px;
  }

  .call-us{
    color: #509a39;
    background: none;
  }
}

#pledge-of-confidence{
  background-color: black;
  color: white;

  .logo-big{
    padding-top: 20px;
    max-height: 100px;
  }
  .logo-small{
    max-height: 75px;
  }
  .el{
    text-align: right;
  }

  .arrow-call-us .glyphicon{
    color: $black-text;
  }
}

#links{
  background-color: $grey-light;
  padding: 80px 0;

  img {
    max-height: 133px;
  }
}

#gamme{
  background-color: $bg-light;
  font-size: 14px;

  .big{
    font-size: 17px;
  }

  h2{
    font-weight: 700;
  }
  .logo{
    padding-top: 30px;
    max-height: 90px;
  }
  .list{
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: right;
    color: black;
    float: right;

    li{
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .carousel-indicators{
    bottom: 0 !important;

    li{
      background-color: black;
      &.active{
        background-color: $green;
      }
    }
  }
}

#partenaires-et-certifications{
  padding: 20px 0;
  h3{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .el a img{
    margin: auto;
  }
}


#arguments {
  background-color: $grey-light;
  padding-bottom: 2em;
  box-shadow: inset 0 5px 10px -2px rgba(0, 0, 0, 0.4);
  text-align: center;
  color: white;
  .info {
    display: inline-block;
    width: 19%;
    font-size: .8em;
    font-weight: bold;
    text-align: center;
    color: white;
    a {
      text-decoration: none;
      color: white;
      &:hover {
        text-decoration: none;
      }
      img {
        display: block;
        margin: 5px auto 15px;
        max-width: 100%;
      }
    }
  }
}
