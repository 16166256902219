.products {
  padding: 40px 0 0 0;
  background-color: white;

  .carousel {
    background-color: white;
    height: 100%;
    min-height: 100%;
    min-height: 55vh;

    .carousel-control.right,
    .carousel-control.left {
      background-image: none;
      background-repeat: no-repeat;
    }

    .carousel-inner {
      min-height: 60vh;
      padding-bottom: 190px;
      .item {
        min-height: 60vh;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        .carousel-caption {
          left: 0;
          right: 0;
          bottom: -190px;
          background-color: white;
          padding-top: 15px;
          padding-bottom: 35px;
          color: black;
          text-shadow: none;

          h2 {
            color: black;
            text-transform: uppercase;
            font-weight: 300;
          }
        }
      }
    }

    .carousel-inner > .item > img {
      margin: 0 auto;
    }

    .carousel-indicators {
      bottom: 0;


      li{
        background-color: $green;
        border: 1px solid transparent;
        &.active {
          border-color: black;
        }
      }
    }
  }
}
