#main {
  min-height: 60vh;
  padding: 10rem 0 5rem;

  &.home,
  &.products {
    padding: 0;
  }

  &.page {
    background-color: #f0f2f0;

    > .container {
      > .row {
        background-color: white;
        border: 1px solid #d8dad8;
        box-shadow: 0 0 25px 4px rgba(0, 0, 0, .1);
        padding: 2em;
      }
    }

    h1 {
      text-transform: uppercase;
      margin-bottom: 40px;
    }

    h2,
    h3 {
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    hr {
      border-top: 2px solid #dbdbdb;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
}

.col-slider {
  min-height: 450px;

  .slide {
    height: 100%;
  }

  .carousel {
    height: 100%;
    min-height: 450px;
    .carousel-inner {
      height: 100%;
      min-height: 450px;
      .item {
        height: 100%;
        min-height: 450px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }
    }

    .carousel-control.right,
    .carousel-control.left {
      background-image: none;
      background-repeat: no-repeat;
    }
  }
}

.row-dark {
  background-color: $grey;
  color: white;
}

.col-content {
  padding: 3em;
  font-size: 1.6em;
  line-height: 2em;

  h2 {
    margin-top: 0;
  }

  &.content-sm {
    font-size: 1.2em;
    line-height: 1.4em;

    p {
      margin-bottom: 1.4em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.call-us {
  width: 240px;
  padding: 1em;
  background-color: $grey;
  color: white;
  text-align: center;
  text-transform: uppercase;

  &.green {
    color: $green;
  }

  span,
  strong {
    display: block;
    letter-spacing: 2px;
  }

  strong {
    font-size: 1.4em;
    letter-spacing: 3px;
  }

  a.btn {
    color: black;
  }
}

.form-group label {
  color: #879e25;
}

#intro {
  background-color: #f0f2f0;
  color: black;
  box-shadow: inset 0 5px 10px -2px rgba(0, 0, 0, 0.4);
}

#pose {
  border-bottom: 80px solid $green;
}

#headline {
  background-color: #1d1c1d;
  padding: 1em;
  ul.list-inline {
    margin-bottom: 0;
    li {
      a {
        color: white;
        text-transform: uppercase;
        &:hover {
          color: $green;
          text-decoration: none;
        }
      }
    }
  }
  .col-callus {
    color: $green;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
    strong {
      font-size: 1.4em;
      line-height: 1em;
    }
  }
}

#realisations {
  ul {
    li {
      line-height: 1.5em;
      a {
        font-size: .8em;
        line-height: 1em;
      }
    }
  }

  .call-us {
    position: absolute;
    top: 20%;
    right: 5%;
    width: 300px;
    span {
      margin-bottom: 0;
    }
    strong {
      font-size: 1.3em;
    }
  }
}
