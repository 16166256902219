#news {
  .carousel {
    background-color: $grey;
    height: 100%;
    min-height: 70vh;

    .carousel-control.right,
    .carousel-control.left {
      background-image: none;
      background-repeat: no-repeat;
    }

    .carousel-inner {
      min-height: 70vh;
      .item {
        min-height: 70vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        .carousel-caption {
          top: 15%;
          left: 0;
          right: 0;
          bottom: 0;
          padding-top: 20px;
          margin-bottom: 0;

          .title {
            h2 {
              max-width: 80%;
              font-size: 2.4em;
              line-height: 1.6em;
              text-transform: uppercase;
              text-shadow: 0 2px 4px rgba(0, 0, 0, 1);
              text-align: center;
            }
          }

          .content {
            max-width: 80%;
            margin: 0 auto;
            margin-top: 2em;
            padding: 1em;
            background-color: rgba(0, 0, 0, .6);
            font-size: 1.4em;
            text-transform: uppercase;

            strong {
              color: $green;
            }
          }
        }
      }
    }

    .carousel-inner > .item > img {
      margin: 0 auto;
    }

    .carousel-indicators {
      bottom: 0;
      .active {
        background-color: $green;
      }
    }
  }
}
