#team{

  background-color: $bg-light;
  .call-us{
    color: $black-text;
  }

  .content{
    margin-bottom: 20px;
  }
}

#team-pose{
  h2{
    font-size: 18px;
    border-bottom: 1px solid black;
    padding-bottom: 3px;
    font-weight: 600;
  }

  .arrow-call-us{
    display: table;
    margin: auto;

    .glyphicon{
      background-color: $bg-light;
      color: $green2;
    }
  }
  .call-us{
    margin: auto;
    margin-top: 10px;
  }
}

#garanties {
  padding: 30px 0;
  background-color: $bg-light;

  .call-us {
    color: black;
    display: inline-block;
  }

  .logo{
    max-height: 73px;
  }
}

.title-img{
  margin-top: 40px;
  max-height: 70px;
  margin-bottom: 20px;
}