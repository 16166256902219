//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #2a292a !default;
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

// App
$green: #b6d23d !default;
$green2: #509a39;
$grey-light: #999998;
$grey: #5a5a5a !default;
$grey-darker: #494949;
$black-text: #5a5a5a;
$bg-light: #efeeea;

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         $green !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #d9534f !default;

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,900&display=swap');
